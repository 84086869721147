<template>
  <div />
</template>


<script>
export default {
  name: 'CountryMap',
  components: {},
  data: () => ({
    title: 'CountryMap'
  })
};
</script>