<template>
  <vs-row vs-justify="center">
    <!-- /////////////////////////////////////////////
    Wallet Balance
    /////////////////////////////////////////////-->
    <vs-col
      vs-lg="4"
      vs-xs="12"
      vs-sm="12"
    >
      <vs-card>
        <WalletBalance />
      </vs-card>
    </vs-col>
    <!-- /////////////////////////////////////////////
    Referral Earnings
    /////////////////////////////////////////////-->
    <vs-col
      vs-lg="4"
      vs-xs="12"
      vs-sm="12"
    >
      <vs-card>
        <ReferralEarnings />
      </vs-card>
    </vs-col>
    <!-- /////////////////////////////////////////////
    Estimated Sales
    /////////////////////////////////////////////-->
    <vs-col
      vs-lg="4"
      vs-xs="12"
      vs-sm="12"
    >
      <vs-card>
        <EstimatedSales />
      </vs-card>
    </vs-col>
    <!-- /////////////////////////////////////////////
    Realtime Visits
    /////////////////////////////////////////////-->
    <vs-col
      vs-lg="12"
      vs-xs="12"
      vs-sm="12"
    >
      <vs-card>
        <!-- Header -->
        <div class="d-md-flex align-items-center">
          <div>
            <h3 class="card-title">
              Realtime Visits
            </h3>
            <h5 class="card-subtitle text-muted font-weight-normal">
              Overview of Latest Month
            </h5>
          </div>
          <div class="ml-auto mb-4 mb-md-0">
            <vs-select
              v-model="visits1"
              class="w-100px"
            >
              <vs-select-item
                v-for="(item,index) in visitsoptions1"
                :key="index"
                :value="item.value"
                :text="item.text"
              />
            </vs-select>
          </div>
        </div>
        <RealTimeVisits />
      </vs-card>
    </vs-col>
    <!-- /////////////////////////////////////////////
    Active Users
    /////////////////////////////////////////////-->
    <vs-col
      vs-lg="4"
      vs-xs="12"
      vs-sm="12"
    >
      <vs-card>
        <h3 class="card-title">
          Active Users
        </h3>
        <ActiveUsers />
      </vs-card>
    </vs-col>
    <!-- /////////////////////////////////////////////
    Device Visit
    /////////////////////////////////////////////-->
    <vs-col
      vs-lg="4"
      vs-xs="12"
      vs-sm="12"
    >
      <vs-card>
        <h3 class="card-title">
          Device Visit
        </h3>
        <DeviceVisits />
      </vs-card>
    </vs-col>
    <!-- /////////////////////////////////////////////
    Visitors By Countries
    /////////////////////////////////////////////-->
    <vs-col
      vs-lg="4"
      vs-xs="12"
      vs-sm="12"
    >
      <vs-card>
        <h3 class="card-title">
          Visitors By Countries
        </h3>
        <div style="height:200px;">
          <App
            :country-data="countryData"
            default-country-fill-color="#93d5ed"
            high-color="#2961ff"
            low-color="#2961ff"
            country-stroke-color="transparent"
          />
        </div>
        <CountryVisits />
      </vs-card>
    </vs-col>
    <!-- /////////////////////////////////////////////
    Top Selling Products
    /////////////////////////////////////////////-->
    <vs-col
      vs-lg="12"
      vs-xs="12"
      vs-sm="12"
    >
      <vs-card>
        <!-- Header -->
        <div class="d-md-flex align-items-center">
          <div>
            <h3 class="card-title">
              Top Selling Products
            </h3>
            <h5 class="card-subtitle text-muted font-weight-normal">
              Overview of Latest Month
            </h5>
          </div>
          <div class="ml-auto mb-4 mb-md-0">
            <vs-select
              v-model="selling1"
              class="w-100px"
            >
              <vs-select-item
                v-for="(item,index) in sellingoptions1"
                :key="index"
                :value="item.value"
                :text="item.text"
              />
            </vs-select>
          </div>
        </div>
        <SellingProduct />
      </vs-card>
    </vs-col>
    <!-- /////////////////////////////////////////////
    Recent Comments
    /////////////////////////////////////////////-->
    <vs-col
      vs-lg="6"
      vs-xs="12"
      vs-sm="12"
    >
      <vs-card class="card-no-padding">
        <h3
          slot="header"
          class="card-title mb-0"
        >
          Recent Comments
        </h3>
        <RecentComments />
      </vs-card>
    </vs-col>
    <!-- /////////////////////////////////////////////
    Recent Chats
    /////////////////////////////////////////////-->
    <vs-col
      vs-lg="6"
      vs-xs="12"
      vs-sm="12"
    >
      <vs-card>
        <h3
          slot="header"
          class="card-title mb-0"
        >
          Recent Chats
        </h3>
        <RecentChats />
      </vs-card>
    </vs-col>
  </vs-row>
</template>
<script>
// //////////////////////////////////////////
// Import Components Here
// //////////////////////////////////////////
import WalletBalance from '../../widgets/chart-widgets/wallet-balance/WalletBalance';
import ReferralEarnings from '../../widgets/chart-widgets/referral-earnings/ReferralEarnings';
import EstimatedSales from '../../widgets/chart-widgets/estimated-sales/EstimatedSales';
import RealTimeVisits from '../../widgets/chart-widgets/realtime-visits/RealTimeVisits';
import ActiveUsers from '../../widgets/chart-widgets/active-users/ActiveUsers';
import DeviceVisits from '../../widgets/chart-widgets/device-visit/DeviceVisit';
import RecentComments from '../dashboard-components/recent-comments/RecentComments';
import RecentChats from '../dashboard-components/recent-chats/RecentChats';
import CountryVisits from '../dashboard-components/country-visit/CountryVisit';
import SellingProduct from '../dashboard-components/selling-product/SellingProduct';
import App from 'vue-world-map';

// //////////////////////////////////////////
// Export Here
// //////////////////////////////////////////
export default {
  name: 'AnalyticalDashboard',
  components: {
    WalletBalance,
    ReferralEarnings,
    EstimatedSales,
    RealTimeVisits,
    ActiveUsers,
    DeviceVisits,
    RecentComments,
    RecentChats,
    CountryVisits,
    SellingProduct,
    App
  },
  data: () => ({
    title: 'AnalyticalDashboard',
    // real time visits
    visits1: 0,
    visitsoptions1: [
      { text: 'Monthly', value: 0 },
      { text: 'Daily', value: 1 },
      { text: 'Weekly', value: 2 },
      { text: 'Yearly', value: 3 }
    ],
    // top selling
    selling1: 0,
    sellingoptions1: [
      { text: 'Monthly', value: 0 },
      { text: 'Daily', value: 1 },
      { text: 'Weekly', value: 2 },
      { text: 'Yearly', value: 3 }
    ],
    countryData: {
      US: 100,
      IN: 200
    }
  })
};
</script>